

import React from 'react'
import SectionHeading from '../SectionHeading'


import { Link } from "gatsby";

// import {
//     CircularProgressbar,
//     CircularProgressbarWithChildren,
//     buildStyles
// } from "react-circular-progressbar";
// import ChangingProgressProvider from "./ChangingProgressProvider";


import './Skills.scss'


function Skills() {
    // const percentage = 50;
    return (
        <section className="skills">
            <SectionHeading heading={'Skills'} subheading={'My Technical Skills'} width={'200%'} right={'-8rem'} />
            <div className="skills__content">
                <div className='flex'>
                    <div className='container'>
                        <h1>As a Frontend Devloper</h1>
                        <h2>Working More than 3 years</h2>
                        <ul>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    HTML 5
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    CSS 3
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    SCSS
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    JAVA SCRIPT
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    REACT JS
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    GATSBY JS
                                </span>
                            </li>

                        </ul>
                        <i className="bi bi-laptop"></i>
                    </div>
                    <div className='container'>
                        <h1>As a Backend Devloper</h1>
                        <h2>Working More than 3 years</h2>
                        <ul>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    NODE JS
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    EXPRESS JS
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    MONGO DB
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    MY SQL
                                </span>
                            </li>
                        </ul>
                        <i className="bi bi-hdd-rack-fill"></i>
                    </div>
                </div>
                <div className='flex'>
                    <div className='container'>
                        <h1>Programming Language</h1>
                        <h2>Learning since 4 years</h2>
                        <ul>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    C/C++
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    JAVASCRIPT
                                </span>
                            </li>
                        </ul>
                        <i className="bi bi-code-slash"></i>
                    </div>
                    <div className='container'>
                        <h1>Tools I familiar with</h1>
                        <h2>Using since 4 years</h2>
                        <ul>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    GITHUB
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    GITBASH - GIT VERSION CONTROL
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    VS CODE
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    LINUX COMMAND LINE
                                </span>
                            </li>
                            <li>
                                <span className='point1'></span>
                                <span className='point2'>
                                    VIM
                                </span>
                            </li>
                        </ul>
                        <i className="bi bi-tools"></i>
                    </div>
                </div>
            </div>

            <div className="btn--div">
                <Link className='btn-primary btn-v2' to="/skill">
                    See more
                    <i className="bi bi-arrow-right-short"></i>
                </Link>
            </div>

        </section>
    )
}

export default Skills