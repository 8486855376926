import React from 'react'
import './AboutMe.scss'
import nabin_bhandari_2 from "/src/images/nabin_bhandari_2.webp";
// import cv from "/src/static/cv.pdf"
import cv from "/src/static/Resume.pdf"



import SectionHeading from '../SectionHeading'

const Aboutme = () => {
    return (
        <section className="aboutme">
            <SectionHeading heading={'About Me'} subheading={'My Introduction'} />
            <div className="aboutme__content">
                <div className='img'>
                    <span className='img__span'>
                        <img src={nabin_bhandari_2} alt="Nabin Bhandari JavaScript Developer"/>
                    </span>
                    <div className='container'>
                        <div>
                            <span className='numbers'>05+</span>
                            <span className='dis'>Years <br /> experience</span>
                        </div>
                        <div>
                            <span className='numbers'>03+</span>
                            <span className='dis'>Completed <br /> projects</span>
                        </div>
                        <div>
                            <span className='numbers'>20+</span>
                            <span className='dis'>Open source <br /> contribution</span>
                        </div>
                    </div>
                </div>
                <p>I'm a Full Stack Web Developer & have more than 5 year's of experience in Web Technologies like Html Css JavaScript React Gatsby Node and many more and little bit knowledge in Ui / Ux design.</p>


                <div className="btn--div">
                    <a  rel="noreferrer" href={cv} id="protfolio--but" className='btn-primary' download target="_blank">
                        Download CV &nbsp; &nbsp;&nbsp;
                        <i className="bi bi-chevron-double-down"></i>
                        {/* <i class="bi bi-circle-fill"></i> */}
                    </a>

                </div>
            </div>
        </section>
    )
}


export default Aboutme