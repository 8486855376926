import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export const Head = ({ content = "" }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <title>{`${content} | ${data.site.siteMetadata.title}`}</title>
    </>
  )

}


