import React from 'react'
import './SectionHeading.scss'

export default function SectionHeading({ heading, subheading, width = 'auto', right = '-3rem' }) {
  const SubStyle = {
    right,
    width,
  }
  return (
    <h2 className='subHeading'>
      <div className="wrapper">
        <span className='subHeading__main'>
          {heading}
        </span>
        <span style={SubStyle} className='subHeading__sub'>
          {subheading}
        </span>
      </div>
    </h2>
  )
}

