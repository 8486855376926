import * as React from "react";

import Layout from "../components/Layout";
import { Head } from "../components/Header/Head";

import Hero from "../components/Index/Hero/Hero";
import Aboutme from "../components/Index/AboutMe/Aboutme";
import Skills from "../components/Index/Skills/Skills";
import Inbox from "../components/Index/Inbox/Inbox";
import Address from "../components/Index/Address/Address";
import Projects from "../components/Index/Projects/Projects";
import CommingSoon from "../components/Global/CommingSoon";
import Github from "../components/Index/GitHub/Github";

const IndexPage = () => {

  return (
    <Layout>
      <Head content={`Home`} />
      <Hero />
      <Aboutme />
      <Skills />
      <Projects />
      <Inbox />
      <Address />
      <Github />
      {/* <CommingSoon text={'SOMETHING'} /> */}
    </Layout>
  );
};

export default IndexPage;




// export function Head() {
//   return (
//     <title>Hello World sdfasf asf</title>
//   )
// }

