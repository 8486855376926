import React from 'react'
import './Inbox.scss'

function Inbox() {
    return (
        <section className="inbox">
            <div className='inbox__container'>
                {/* <p>Wanna Work with me</p>
                <span>I'm currently available for work.</span> */}
                <p>Wanna collaborate with me?</p>
                <span>I'm currently planning to research in AI</span>
                <div className="btn--div--inbox">
                    <a className='btn-primary' href="mailto: nabin123456j@gmail.com">
                        Send Email &nbsp;&nbsp;&nbsp;
                        <i className="bi bi-envelope"></i>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Inbox