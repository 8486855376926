import React from 'react'

function Link({ row = '' }) {
    return (
        <div className={`hero__link ${row ? "tesst" : ""}`}>
            <a href="https://github.com/devnabin" target='_blank' rel="noreferrer" role="button">
                <i className="hero__link--item bi bi-github"></i>
            </a>
            <a href="https://www.linkedin.com/in/bhandarinabin" target='_blank' rel="noreferrer" role="button">
                <i className="hero__link--item bi bi-linkedin"></i>
            </a>
            <a href="https://x.com/devnabeen" target='_blank' rel="noreferrer" role="button">
                <i className="hero__link--item bi bi-twitter"></i>
            </a>
            <a href="https://www.instagram.com" target='_blank' rel="noreferrer" role="button">
                <i className="hero__link--item bi bi-instagram"></i>
            </a>
        </div>

    )
}

export default Link