import React, { useState } from 'react'

function Project({ heading, description, tech, pic, number, gitLink, liveLink }) {
    const [showImage, setShowImage] = useState(false)
    tech = tech.split(' ')
    return (
        <div className="section__project__content__project">
            {/* <h2></h2> */}
            <h3>
                <span>
                    {number} &nbsp;
                </span>
                {heading}
            </h3>
            <p>
                {description}
            </p>
            <div className="techUsed">
                <h4>Tech used :</h4>
                <div className="tech">
                    {
                        tech.map(value => <span key={value}>{value}</span>)
                    }
                </div>
            </div>
            <div className="but">
                <a href={gitLink} target="_blank"  rel="noreferrer">
                    <i className="bi bi-github"></i>
                    <br />
                    <span>Github Link</span>
                </a>
                <a href={liveLink} target="_blank"  rel="noreferrer">
                    <i className="bi bi-eye-fill"></i>
                    <br />
                    <span>Live Demo</span>
                </a>

                <span className='aTagSpan' onClick={() => setShowImage(true)}>
                    <i className="bi bi-image"></i>
                    <br />
                    <span>See Image</span>
                </span>
            </div>
            <div onClick={() => setShowImage(false)} style={{ left: showImage ? '0' : '-100%' }} className='proimage'>
                <img className="img_edit" src={pic} alt="" />
            </div>
        </div>
    )
}

export default Project