import React from 'react'
import Logo from '../../Header/Logo';
import Link from '../Hero/Link'
import SectionHeading from '../SectionHeading';
import './Address.scss'

function Address() {
    return (
        <section className='section__address'>
              <SectionHeading heading={'Catch me up'} subheading={'My Contact Details'} width={'200%'} right={'-8rem'} />
            <div className="section__address__content">
                {/* <h3>catch me up</h3> */}
                <div className="section__address__content__flex1">
                    <div className='address__contact'>
                        <i className="bi bi-geo-alt-fill"></i>
                        <p>
                            <span>location</span>
                            <br />
                            <span>  Ghorahi-18, Dang</span>
                        </p>
                    </div>
                    <div className='address__contact'>
                        <i className="bi bi-telephone-fill"></i>
                        <p>
                            <span>contact</span>
                            <br />
                            <span>  +9779812870775</span>
                        </p>
                    </div>
                    <div className='address__contact'>
                        <i className="bi bi-envelope-fill"></i>
                        <p>
                            <span>mail</span>
                            <br />
                            <span>    nabin123456j@gmail.com </span>
                        </p>
                    </div>
                </div>
                <div className="section__address__content__flex2">
                    <Logo />
                    <div className='me'>
                        <span>Nabin Bhandari</span>
                        <span>Full MERN Stack web developer</span>
                    </div>
                    <div className="me__center">
                        <span>Follow me</span>
                        <span>I would love to connect with you.</span>
                        <Link row={'row'} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Address