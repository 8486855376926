import React from "react";
import Project from "./Project";

import { Link } from "gatsby";

import SectionHeading from "../SectionHeading";

import "./Projects.scss";

import chatapp from "../../Project/ChatApp/chatpp.jpg";

function Projects() {
  return (
    <section className="section__project">
      <SectionHeading heading={"Projects"} subheading={"My own Projects"} />
      <div className="section__project__content">
        <Project
          number="#1"
          heading="Chat Application"
          description="Chat Application where A user create room and other user can join that group and can text any one in that group."
          tech="node express.js socket.io"
          pic={chatapp}
          liveLink={"https://binchat.herokuapp.com/"}
          gitLink={"https://github.com/Nabiin/chatapp"}
        />
        <Project
          number="#2"
          heading="My Site"
          description="This first project from where I have learn AJAX , APIS , NODE SERVER (EXPRESS.Js) , Mongo db database and many more."
          tech="node express.js mongodb"
          pic={chatapp}
          gitLink={"https://github.com/Nabiin/mysite"}
          liveLink={"https://binedu.herokuapp.com/"}
        />
        <div className="btn--div">
          <Link
            className="btn-primary btn-v2"
            activeClassName="active"
            to="/project"
          >
            See more
            <i className="bi bi-arrow-right-short"></i>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Projects;
