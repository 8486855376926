import React from "react";
import SocialLink from './Link'
import './Hero.scss'
import nabin_bhandari from "/src/images/nabin_bhandari_webdev.webp";


const Hero = () => {

  return (
    <section>
      <div className="hero">
        <SocialLink />
        <div className="hero__details">

          <h1>Hi, I’m  &nbsp;
            <span className="fname">
              Nabin
            </span>
            {/* <br /> */}
            {/* <div className="lname">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
            Bhandari
          </div> */}

          </h1>
          {/* <Link className="btn" to="/contact/">
          Contact me 
        </Link> */}


        </div>
        <span className="img1">
          <img src={nabin_bhandari} className="img11" alt="Nabin Bhandari JavaScript Developer" />
        </span>
        {/* <div className="hero__picww">
      </div> */}
      </div>



      <div className="scrollDown">
        <span>
          scroll down &nbsp;
        </span>
        {/* <i className="bi bi-arrow-down-short"></i> */}
        <i className="bi bi-chevron-double-down"></i>
      </div>


    </section >








  );
};

export default Hero;
