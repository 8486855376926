import React, { useEffect, useState } from 'react';
import fetch from 'node-fetch';

import SectionHeading from '../SectionHeading';

import github from '/src/images/github.gif'
import typeGif from '/src/images/type.gif'
import dogGif from '/src/images/dog.gif'

import './Github.scss'
const Github = () => {

    const [devName, setDevName] = useState('');
    const [userData, setUserData] = useState(null);

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const apiUrl = `https://api.github.com/users/${devName}`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            console.log(data);
            if (data.message) return setUserData(null)
            setUserData(data);
        } catch (error) {
            setUserData(null)
            // console.error('Error fetching data:', error);
        }

    }



    return (
        <section className='section__github'>
            <SectionHeading heading={'GitHub Friend'} subheading={'See GitHub Profile'} width={'200%'} right={'-8rem'} />
            <div className='section__github--content'>

                <div className='section__github--content-flex-1'>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="text"
                                name='dev'
                                className="form__input"
                                placeholder="Enter Github UserName"
                                id="name"
                                onChange={e => setDevName(e.target.value)}
                                required />
                            <label htmlFor="name" className="form__label">searcing a dev...</label>
                        </div>

                        <button type="submit">
                            Search
                        </button>
                    </form>
                </div>

                <div className='section__github--content-flex-2'>

                    {
                        userData ? <a href={userData.html_url} target='_blank'>
                            <img id='gitimg' src={github} alt="" srcset="" />
                            <img id='gitProfile' src={userData.avatar_url} alt="GitHub Avatar" />
                            <p>Name: {userData.name}</p>
                            <p>Address: {userData.location}</p>
                        </a> :
                            <div>

                                {
                                    devName ? <img src={dogGif} alt="" srcset="" /> :
                                        <img src={typeGif} alt="" srcset="" />
                                }


                                <div className='invalidDevName'>
                                    {
                                        devName ? `who is ${devName}?` : 'Try to search like "torvalds"'
                                    }
                                </div>
                            </div>
                    }


                </div>
            </div>
        </section>

    );
};

export default Github;

